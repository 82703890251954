<template>
  <div class="Details">
    <div class="paddingTopHeader blockDiv">
      <img src="../assets/images/detailsbanner.png" alt="" />
    </div>
    <div class="item1 paddingTop4 paddingBot4">
      <div class="autowidth">
        <h3 class="txtCenter color4A4949 pt20 font24 fontBold">为诊所提供信息化、数字化、智能化一站式解决方案</h3>
        <dl class="paddingTop3">
          <dt class="font20 txtLeft">
            <p>
              同云诊所服务平台系湖南同云医疗科技有限公司旗下的诊所服务品牌，以“首诊问诊、轻症问诊、慢病问诊”为定位，为诊所提供数字化一站式解决方案，让诊所在短时间内提升数字化管理水平，逐步实现智慧医疗的信息化管理和高标准化诊疗。
            </p>
            <p>经营流程数字化、便捷化、信息化，也能缓解患者看病难，看病贵等现象。</p>
          </dt>
          <dd>
            <img src="../assets/images/detail1.png" alt="" />
          </dd>
        </dl>
      </div>
    </div>
    <div class="item2 paddingTop4 paddingBot4" style="background: #fafafa">
      <div class="autowidth">
        <h3 class="txtCenter color4A4949 pt20 font24 fontBold">
          <span class="span1">满足多端应用场景：PC端+移动端</span>
          <span class="pc-show">，</span>
          <span class="span2">助力基层医疗机构高效运营</span>
        </h3>
        <div class="paddingTop1 marginTop5 marginBot5 txtCenter">
          <img src="../assets/images/detail2.png" alt="" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'

export default {
  name: 'Details',
  components: {
    Footer
  }
}
</script>

<style lang="scss" scoped>
.item1 {
  text-align: left;
  dl {
    height: auto;
    overflow: hidden;
  }
  dd {
    width: 35%;
    margin-right: 5%;
    float: left;
  }
  dt {
    width: 60%;
    margin-top: 2%;
    line-height: 2;
    float: right;
    p {
      text-indent: 2em;
    }
  }
  img {
    width: 100%;
  }
}
@media only screen and (max-width: 640px) {
  .item1 dt {
    width: 100%;
    float: inherit;
    margin-top: 0;
  }
  .item1 dd {
    float: inherit;
    width: 80%;
    margin: 0 auto 1%;
  }
  .item2 {
    padding-bottom: 50px;

    .pc-show {
      display: none;
    }
    .span1 {
      margin-left: -20%;
    }
    .span2 {
      margin-left: 20%;
    }

    h3 span {
      display: block;
    }
  }
  /deep/ .aboutBox {
    display: none;
  }
}
</style>
